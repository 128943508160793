import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';

import Banner from '../common/banner.js'

const HubTableRow = ({category, type, hub, path, isHidden}) => {

    return (
        <tr>
            <td className="px-6 py-4 whitespace-no-wrap">
                {category}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
                {type}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${!isHidden ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {isHidden ? 'Oculto' : 'Visible'}
                </span>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
                <Link to={{
                    pathname: path,
                    state: { 
                        hub: hub
                    }
                }}>
                    <button className="text-indigo-600 hover:text-indigo-900">Ver</button>
                </Link>
            </td>
        </tr>
    );
}

const HubTable = () => {
    const [hubCategories, setHubCategories] = useState(null);
    const [hiddenBanner, setHiddenBanner] = useState(true);
    const [bannerData, setBannerData] = useState({
        smallText: "Cargando...",
        fullText: "El contenido está cargando...",
        color: "blue",
        type: "info"
    });

    const toggleBanner = useCallback(() => {
        setHiddenBanner(!hiddenBanner);
    }, [hiddenBanner]);

    useEffect(() => {
        async function getHubCategories() {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            try {
                const result = await fetch('/api/SignalRHub/GetCategories', requestOptions)
                    .then(response => response.json());
                    if (result.statusCode === 200 && result.status === "OK") {
                        setHubCategories(result.hubCategories);
                    } else {
                        setBannerData({
                            smallText: result.statusCode + ' ' + result.status,
                            fullText: result.message,
                            color: "red",
                            type: "error"
                        });
                        toggleBanner();
                    }
            } catch (error) {
                setBannerData({
                    smallText: error.message,
                    fullText: error.message,
                    color: "red",
                    type: "error"
                });
                toggleBanner();
            }
        }
        getHubCategories();
    }, [toggleBanner]);

    return (
    <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                {!hiddenBanner ? <Banner {...bannerData} toggleBanner={toggleBanner}/> : null }
                { hubCategories ? (
                    hubCategories.length > 0 ? 
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-200">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                            Categoría
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                            Sub categoría
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                            Visible
                                        </th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    { hubCategories.map((category) => <HubTableRow {...category} key={category.category + category.type} /> ) }
                                </tbody>
                            </table>
                        </div>
                    : <div className="text-lg leading-5 font-medium text-gray-900 mt-2">No se han encontrado categorías...</div>
                ) : <div className="text-lg leading-5 font-medium text-gray-900 mt-2">Cargando categorías...</div> }
            </div>
        </div>
    </div>
    );
}

export default withRouter(HubTable);