import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import {Helmet} from "react-helmet";
import TopNavBar from '../../partials/TopNavBar.js'
import SideNavBar from '../../partials/SideNavBar.js'
import EditReportForm from './editReportForm.js'

const baseUrl = window.location.origin;

const EditReport = ({user, reportMenu, menu, organizations, changeSelectedReport, changeSelectedCategory, changeSelectedOrganization, location }) => {
    const [report, setReport] = useState({});

    const _menu = [...menu];
    _menu.forEach(element => {
        element.active = false
        if (element.message === "Reportes") {
            element.active = true;
        }
    });

    useEffect(() => {
        async function getReport() {
            const reportRequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: location.state.reportId,
                    tenantId: location.state.tenantId,
                    organizationId: location.state.organizationId
                })
            };
            try {
                const result = await fetch(baseUrl + '/api/report/GetReport', reportRequestOptions)
                    .then(response => response.json())
                if (result.statusCode === 200 && result.status === "OK") {
                    setReport({...result.report});
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        getReport();
    }, [location.state]);

    return (
        <div className="dashboard h-screen">
            <Helmet>
                <title>XemanticA - Modificar Reporte</title>
            </Helmet>
            <div className="top-nav-bar">
                <TopNavBar user={user} reportMenu={reportMenu} menu={menu} organizations={organizations} changeSelectedReport={changeSelectedReport} changeSelectedOrganization={changeSelectedOrganization} />
            </div>
            <div className="mid-section flex flex-row" style={{height: "calc(100vh - 4rem)"}}>
                <div className="side-nav-bar hidden lg:block">
                    { reportMenu ? <SideNavBar reportMenu={reportMenu} changeSelectedReport={changeSelectedReport} changeSelectedCategory={changeSelectedCategory}/> : null}
                </div>
                <div className="editReport-container flex flex-1">
                    <div className="bg-gray-200 w-full overflow-auto">
                        <div className="max-w-6xl mx-auto lg:py-6 sm:px-6 lg:px-8">
                            { user && report.name ? <EditReportForm user={user} report={report} /> : <h1>Cargando...</h1>}
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default withRouter(EditReport);