import React from 'react';

const ProfileNormalOptions = () => {
    return (
        <div className="">
            <button className="px-4 py-2 pb-4 hover:bg-gray-100 flex w-full">
                <p className="text-sm font-medium text-gray-800 leading-none">Changelog</p>
            </button>
            <button className="px-4 py-2 pb-4 hover:bg-gray-100 flex w-full">
                <p className="text-sm font-medium text-gray-800 leading-none">Estado del servicio</p>
            </button>
            <button className="px-4 py-2 pb-4 hover:bg-gray-100 flex w-full">
                <p className="text-sm font-medium text-gray-800 leading-none">Support &amp; FAQ</p>
            </button>
            <button className="px-4 py-2 pb-4 hover:bg-gray-100 flex w-full" onClick={() => window.location.replace(window.location.origin + '/AzureAD/Account/SignOut')}>
                <p className="text-sm font-medium text-gray-800 leading-none">Logout</p>
            </button>
        </div>
    );
}

export default ProfileNormalOptions;