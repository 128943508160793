import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { InfoIcon, WarningIcon, ErrorIcon, SuccessIcon } from './icons.js';

const Banner = ({toggleBanner, smallText, fullText, redirect, redirectMessage, color, type}) => {
    
    if (!color) {
        color = "blue";
    }

    function renderIcon(type){
        switch(type) {
            case 'warning':
                return <WarningIcon />;
            case 'error':
                return <ErrorIcon />;
            case 'success':
                return <SuccessIcon />;
            default:
                return <InfoIcon />;
          }
    }

    return (
        <div className={`bg-${color}-500`}>
            <div className="max-w-screen-xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">

                    <div className="w-0 flex-1 flex items-center">
                        <span className={`flex p-2 rounded-lg bg-${color}-600`}>
                            {renderIcon(type)}
                        </span>
                        <p className="ml-3 font-medium text-white">
                            <span className="md:hidden">
                                {smallText}
                            </span>
                            <span className="hidden md:inline">
                                {fullText}
                            </span>
                        </p>
                    </div>

                    { redirect && redirectMessage ? 
                    <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <div className="rounded-md shadow-sm">
                            <Link to={redirect}>
                                <button className={`flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-${color}-600 bg-white hover:text-${color}-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150`}>
                                    {redirectMessage}
                                </button>
                            </Link>
                        </div>
                    </div> : null }
                    
                    <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                        <button type="button" className={`-mr-1 flex p-2 rounded-md hover:bg-${color}-500 focus:outline-none focus:bg-${color}-500 sm:-mr-2 transition ease-in-out duration-150`} aria-label="Dismiss" onClick={() => toggleBanner()}>
                            {/* Heroicon name: x */}
                            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Banner);