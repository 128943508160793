import React from 'react';
import MobileSelectedMenuOption from './mobileSelectedMenuOption.js';
import MobileMenuOption from './mobileMenuOption.js';

const MobileReportMenuCategory = (props) => {
    return (
        <li>
            <span className="block pl-1 pr-3 py-2 rounded-md text-base font-medium w-full text-justify text-white focus:outline-none">{props.value}</span>
            <ul>
                {props.reports.map((report) => !report.isHidden ?
                    (report.isActive ? 
                        <MobileSelectedMenuOption key={report.id + report.name} value={report.name} onClick={() => props.changeReport(report)} /> :
                        <MobileMenuOption key={report.id + report.name} value={report.name} onClick={() => props.changeReport(report)} />) :
                    null)}
            </ul>
        </li>
    );
}

export default MobileReportMenuCategory;