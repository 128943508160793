import React, { useState } from 'react';
import { Transition } from '@tailwindui/react';

const Organization = ({isSelected, organization, changeSelectedOrganization}) => {
    return (
        <li id="listbox-item-0" role="option" aria-selected className="text-gray-900 cursor-default select-none relative hover:bg-gray-200">
            <button className="flex items-center py-2 pr-9" onClick={ !isSelected ? (() => changeSelectedOrganization(organization)) : null }>
                <span className={`ml-3 block truncate ${isSelected ? "font-semibold" : "font-normal"}`}>
                {organization.displayName}
                </span>

                { isSelected ? <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                    {/* <!-- Heroicon name: solid/check --> */}
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                </span> : null }
            </button>
        </li>
    );
}

const SelectedOrganization = ({displayName}) => {
    return (
        <div>
            <span className="flex items-center">
                <span className="ml-3 block truncate">
                {displayName}
                </span>
            </span>
            <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                {/* Heroicon name: solid/selector */}
                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </span>
        </div>
    );
}

const OrganizationDropdown = ({user, organizations, changeSelectedOrganization}) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectedOrganization = user ? organizations.find(data => data.organizationId === user.organizationId) : null;

    return (
        <div>
            <div className="mt-1 relative">
                <button type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" style={{minWidth: "10rem"}} onClick={() => setIsOpen(!isOpen)}>
                    { selectedOrganization ? <SelectedOrganization {...selectedOrganization} /> : <SelectedOrganization displayName="None" />}
                </button>

                <Transition show={isOpen} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
                        <ul tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" className="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            { organizations.map((organization) => <Organization isSelected={user.organizationId === organization.organizationId} organization={organization} changeSelectedOrganization={changeSelectedOrganization} key={organization.organizationId} />) }
                        </ul>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default OrganizationDropdown;