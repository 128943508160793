import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import CategoryTable from './categoryTable';

const CategoryList = ({reportMenu}) => {
 return (
    <div className="md:grid md:grid-cols-1">
        <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-2xl font-medium leading-6 text-gray-900">Listado de Categorías</h3>
                        <p className="mt-1 text-sm leading-5 text-gray-600 mb-5">
                        Estas categorías podrán verlas cualquier persona perteneciente al tenant de tu organización.
                        </p>
                        <span className="sm:ml-3 shadow-sm rounded-md">
                            <Link to="/category/newCategory">
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-indigo focus:border-green-700 active:bg-green-700 transition duration-150 ease-in-out mb-4">
                                    {/* Heroicon: plus */}
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                    </svg>
                                    Nueva categoría
                                </button>
                            </Link>
                        </span>
                        <CategoryTable reportMenu={reportMenu} />
                    </div>
                </div>
            </div>
        </div>
    </div>
 );
}

export default withRouter(CategoryList);