import React from 'react';
import {Helmet} from "react-helmet";
import TopNavBar from '../partials/TopNavBar.js'
import SideNavBar from '../partials/SideNavBar.js'

const Report = (props) => {
    // Info: Titulo no cambia cuando se retrocede en el historial de navegación, debido a que el componente no re-renderiza
    return (
      <div className="flex flex-col w-full">
        <Helmet>
            <title>XemanticA - {props.name}</title>
        </Helmet>
        <iframe title={props.name} className="h-full" src={props.url} frameBorder="0" allowFullScreen={true}></iframe>
      </div>
    );
}
  
const Dashboard = ({user, reportMenu, menu, organizations, changeSelectedReport, changeSelectedCategory, changeSelectedOrganization }) => {

    const _menu = [...menu];
    _menu.forEach(element => {
        element.active = false
        if (element.message === "Dashboard") {
            element.active = true;
        }
    });

    function getActiveReportUrl() {
        let reportUrl = "";
        reportMenu.forEach(category => {
            category.reports.forEach(report => {
                if (report.isActive) {
                    reportUrl = report.url;
                }
            });
        });
        return reportUrl;
    }

    function getActiveReportName() {
        let reportName = "";
        reportMenu.forEach(category => {
            category.reports.forEach(report => {
                if (report.isActive) {
                    reportName = report.name;
                }
            });
        });
        return reportName;
    }

    return (
        <div className="dashboard h-screen">
            <Helmet>
                <title>XemanticA - Dashboard</title>
            </Helmet>
            <div className="top-nav-bar">
                <TopNavBar user={user} reportMenu={reportMenu} menu={menu} organizations={organizations} changeSelectedReport={changeSelectedReport} changeSelectedOrganization={changeSelectedOrganization}/>
            </div>
            <div className="mid-section flex flex-row" style={{height: "calc(100vh - 4rem)"}}>
                <div className="side-nav-bar hidden lg:block">
                    <SideNavBar reportMenu={reportMenu} changeSelectedReport={changeSelectedReport} changeSelectedCategory={changeSelectedCategory}/>
                </div>
                <div className="report-iframe flex flex-1">
                    <Report url={getActiveReportUrl()} name={getActiveReportName()}/>
                </div>
            </div>  
        </div>
    );
}

export default Dashboard;