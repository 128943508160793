import React, { useState } from 'react';
import { withRouter } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from "yup";

import { SimpleRequiredInput, SimpleTextArea, SimpleCheckbox } from '../common/formValidator.js'
import { TextInput, TextArea, Checkbox } from '../common/formFields.js'
import Banner from '../common/banner.js'

const baseUrl = window.location.origin;

const NewCategoryForm = (props) => {
    const [hiddenBanner, setHiddenBanner] = useState(true);
    const [isButtonEnabled, setIsButtonEnabled] = useState(true);
    const [bannerData, setBannerData] = useState({
        smallText: "Cargando...",
        fullText: "El contenido está cargando...",
        color: "blue",
        type: "info"
    });

    async function AddCategoryRequest(data) {
        const addCategoryRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data
        };
        try {
            const result = await fetch(baseUrl + '/api/category/AddCategory', addCategoryRequestOptions)
                .then(response => response.json())
            if (result.statusCode === 200 && result.status === "OK") {
                setBannerData({
                    smallText: "Categoría creado.",
                    fullText: "La categoría ha sido creada con éxito.",
                    color: "green",
                    type: "success"
                });
                toggleBanner();
                setTimeout(() => {
                    props.history.push({ // TODO: Replace for a object update on reports from the index
                        pathname:'/category/all',
                        state: {isReload: true}
                    });
                }, 3000);
            } else {
                setBannerData({
                    smallText: result.statusCode + ' ' + result.status,
                    fullText: result.message,
                    color: "red",
                    type: "error"
                });
                setIsButtonEnabled(true);
                toggleBanner();
            }
        } catch (error) {
            setBannerData({
                smallText: error.message,
                fullText: error.message,
                color: "red",
                type: "error"
            });
            setIsButtonEnabled(true);
            toggleBanner();
        }
    }

    function toggleBanner (){
        setHiddenBanner(!hiddenBanner);
    }

    return (
        <Formik
            initialValues={{
                tenantId: props?.user?.tenantId ?? '',
                organizationId: props?.user?.organizationId ?? '',
                name: '',
                description: '',
                isHidden: false
            }}
            validationSchema={Yup.object({
                tenantId: SimpleRequiredInput,
                organizationId: SimpleRequiredInput,
                name: SimpleRequiredInput,
                description: SimpleTextArea,
                isHidden: SimpleCheckbox
            })}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    setIsButtonEnabled(false);
                    AddCategoryRequest(JSON.stringify(values, null, 0));
                    setSubmitting(false);
                }, 400);
            }}
        >
            <div className="md:grid md:grid-cols-1">
                <div className="mt-5 md:mt-0 md:col-span-2">
                    {!hiddenBanner ? <Banner {...bannerData} toggleBanner={toggleBanner}/> : null }
                    <Form>
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-2xl font-medium leading-6 text-gray-900">Nueva categoría</h3>
                                    <p className="mt-1 text-sm leading-5 text-gray-600 mb-5">
                                    Esta categoría podrá verla cualquier persona perteneciente a tu organización.
                                    </p>
                                </div>
                                <TextInput label="Tenant Id" id="tenantId" name="tenantId" type="text" disabled hidden />
                                <TextInput label="Organización" id="organizationId" name="organizationId" type="text" disabled />
                                <TextInput label="Nombre de la categoría" id="name" name="name" type="text" />
                                <TextArea label="Descripción" id="description" name="description" placeholder="Se verán los primeros 30 carácteres en el listado de categorías." />
                                <Checkbox label="Oculto" id="isHidden" name="isHidden" />
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <span className="inline-flex rounded-md shadow-sm">
                                        <button type="submit" className={`${isButtonEnabled ? `bg-green-600 hover:bg-green-500 focus:border-green-700 active:bg-green-700` : `bg-gray-600 hover:bg-gray-500 focus:border-gray-700 active:bg-gray-700`} inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-green transition duration-150 ease-in-out`} disabled={!isButtonEnabled}>
                                            Confirmar
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    );
}

export default withRouter(NewCategoryForm);