import React from 'react';
import { Field, useField } from 'formik';

const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <div className={`col-span-6 ${props.hidden ? `hidden` : ``}`}>
                <label htmlFor={props.id || props.name} className="block mt-2 text-sm font-medium leading-5 text-gray-700">{label}</label>
                <input className="mt-1 form-input block w-full py-2 px-3 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" {...field} {...props} />
                {meta.touched && meta.error ? (
                    <p className="error mt-2 text-sm text-red-700">
                        {meta.error}
                    </p>
                ) : null}
            </div>
        </>
    );
};

const TextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <div className="mt-6">
                <label htmlFor={props.id || props.name} className="block mt-2 text-sm leading-5 font-medium text-gray-700">{label}</label>
                <div className="rounded-md shadow-sm">
                    <Field as="textarea" {...field} {...props} rows={3} maxLength="4000" className="mt-1 form-input block w-full py-2 px-3 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    {meta.touched && meta.error ? (
                        <p className="error mt-2 text-sm text-red-700">
                            {meta.error}
                        </p>
                    ) : null}
                </div>
            </div>
        </>
    )
}

const Checkbox = ({ label, sublabel, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
        <>
            <div className="mt-4">
                <div className="flex items-start">
                    <div className="flex items-center h-5">
                        <input type="checkbox" {...field} {...props} className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                    </div>
                    <div className="ml-3 text-sm leading-5">
                        <label htmlFor={props.id || props.name} className="font-medium text-gray-700">{label}</label>
                        <p className="text-gray-500">{sublabel}</p>
                        {meta.touched && meta.error ? (
                            <p className="error mt-2 text-sm text-red-700">
                                {meta.error}
                            </p>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

const Select = ({ children, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'select' });
    return (
        <>
            <div className="col-span-6 sm:col-span-3">
                <label htmlFor={props.id || props.name} className="block mt-2 text-sm font-medium leading-5 text-gray-700">{props.label}</label>
                <Field as="select" {...field} {...props} className="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                    {children}
                </Field>
                {meta.touched && meta.error ? (
                    <p className="error mt-2 text-sm text-red-700">
                        {meta.error}
                    </p>
                ) : null}
            </div>
        </>
    );
};

export {
    TextInput,
    TextArea,
    Checkbox,
    Select
}