import React from 'react';
import Logo from '../static/logo2.png';

const Login = () => {

    /*<div style={{backgroundColor: '#1a202c', backgroundImage: 'linear-gradient(315deg, #1a202c 0%, #3d7101 74%)'}} className="h-screen">*/
    return (
        <div style={{backgroundColor: '#1e1e1e'}} className="h-screen">
            <header className="max-w-lg mx-auto ">
                {/* <h1 className="text-4xl font-bold text-white text-center">XemanticA</h1> */}
                <div className="flex-shrink-0 flex justify-center align-center">
                    <a href='http://www.xemantics.com'>
                        <img className="" src={Logo} alt="Xemantics Logo"></img>
                    </a>
                </div>
            </header>

            <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
                <section>
                    <h3 className="font-bold text-2xl">Welcome to XemanticA</h3>
                    <p className="text-gray-600 pt-2">Sign in to your organizational account.</p>
                </section>

                <section className="mt-10 flex flex-col">
                    <button className="bg-green-800 hover:bg-green-900 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200" onClick={() => window.location.replace(window.location.origin + '/AzureAD/Account/SignIn')}>Sign In</button>
                </section>
            </main>

            <footer className="max-w-lg mx-auto flex justify-center text-white">
                <button className="hover:underline">Contact</button>
                <span className="mx-3">•</span>
                <button className="hover:underline">Privacy</button>
            </footer>
        </div>
    );
}

export default Login;