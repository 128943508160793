import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom'
import { Formik, Form } from 'formik';
import * as Yup from "yup";

import { SimpleRequiredInput, UrlRequiredInput, SimpleTextArea, SimpleCheckbox } from '../common/formValidator.js'
import { TextInput, TextArea, Select, Checkbox } from '../common/formFields.js'
import Banner from '../common/banner.js'

const baseUrl = window.location.origin;

const EditReportForm = ({user, report, history}) => {
    const [categories, setCategories] = useState([]);
    const [hiddenBanner, setHiddenBanner] = useState(true);
    const [isButtonEnabled, setIsButtonEnabled] = useState(true);
    const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(true);
    const [isDefaultButtonEnabled, setIsDefaultButtonEnabled] = useState(true);
    const [bannerData, setBannerData] = useState({
        smallText: "Cargando...",
        fullText: "El contenido está cargando...",
        color: "blue",
        type: "info"
    });

    const toggleBanner = useCallback(() => {
        setHiddenBanner(!hiddenBanner);
    }, [hiddenBanner]);

    useEffect(() => {
        async function getCategories() {
            const categoriesRequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    tenantId: user?.tenantId ?? '',
                    organizationId: user?.organizationId ?? '',
                })
            };
            try {
                const result = await fetch(baseUrl + '/api/category/GetUserCategoriesOnly', categoriesRequestOptions)
                    .then(response => response.json())
                if (result.statusCode === 200 && result.status === "OK") {
                    setCategories([...result.categories]);
                } else {
                    setBannerData({
                        smallText: result.statusCode + ' ' + result.status,
                        fullText: result.message,
                        color: "red",
                        type: "error"
                    });
                    setIsButtonEnabled(false);
                    toggleBanner();
                }
            } catch (error) {
                setBannerData({
                    smallText: error.message,
                    fullText: error.message,
                    color: "red",
                    type: "error"
                });
                setIsButtonEnabled(false);
                toggleBanner();
            }
        }
        getCategories();
    }, [toggleBanner, user]);

    async function UpdateReportRequest(data) {
        const updateReportRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data
        };
        try {
            const result = await fetch(baseUrl + '/api/report/UpdateReport', updateReportRequestOptions)
                .then(response => response.json())
            if (result.statusCode === 200 && result.status === "OK") {
                setBannerData({
                    smallText: "Reporte modificado.",
                    fullText: "El reporte ha sido modificado con éxito.",
                    color: "green",
                    type: "success"
                });
                toggleBanner();
                setTimeout(() => {
                    history.push({ // TODO: Replace for a object update on reports from the index
                        pathname:'/report/all',
                        state: {isReload: true}
                    });
                }, 3000);
            } else {
                setBannerData({
                    smallText: result.statusCode + ' ' + result.status,
                    fullText: result.message,
                    color: "red",
                    type: "error"
                });
                setButtonsEnabled(true);
                toggleBanner();
            }
        } catch (error) {
            setBannerData({
                smallText: error.message,
                fullText: error.message,
                color: "red",
                type: "error"
            });
            setButtonsEnabled(true);
            toggleBanner();
        }
    }

    async function DeleteReportRequest() {
        const deleteReportRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tenantId: user?.tenantId ?? '',
                organizationId: user?.organizationId ?? '',
                id: report?.id ?? ''
            }, null, 0)
        }; 
        try {
            const result = await fetch(baseUrl + '/api/report/DeleteReport', deleteReportRequestOptions)
                .then(response => response.json())
            if (result.statusCode === 200 && result.status === "OK") {
                setBannerData({
                    smallText: "Reporte eliminado.",
                    fullText: "El reporte ha sido eliminado con éxito.",
                    color: "green",
                    type: "success"
                });
                toggleBanner();
                setTimeout(() => {
                    history.push({  // TODO: Replace for a object update on reports from the index
                        pathname:'/report/all',
                        state: {isReload: true}
                    });
                }, 3000);
            } else {
                setBannerData({
                    smallText: result.statusCode + ' ' + result.status,
                    fullText: result.message,
                    color: "red",
                    type: "error"
                });
                setButtonsEnabled(true);
                toggleBanner();
            }
        } catch (error) {
            setBannerData({
                smallText: error.message,
                fullText: error.message,
                color: "red",
                type: "error"
            });
            setButtonsEnabled(true);
            toggleBanner();
        }
    }

    async function DefaultReportRequest() {
        const defaultReportRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tenantId: user?.tenantId ?? '',
                organizationId: user?.organizationId ?? '',
                id: report?.id ?? ''
            }, null, 0)
        }; 
        try {
            const result = await fetch(baseUrl + '/api/report/SetDefaultReport', defaultReportRequestOptions)
                .then(response => response.json())
            if (result.statusCode === 200 && result.status === "OK") {
                setBannerData({
                    smallText: "Reporte modificado.",
                    fullText: "El reporte ha sido modificado con éxito.",
                    color: "green",
                    type: "success"
                });
                toggleBanner();
                setTimeout(() => {
                    history.push({ // TODO: Replace for a object update on reports from the index
                        pathname:'/report/all',
                        state: {isReload: true}
                    });
                }, 3000);
            } else {
                setBannerData({
                    smallText: result.statusCode + ' ' + result.status,
                    fullText: result.message,
                    color: "red",
                    type: "error"
                });
                setButtonsEnabled(true);
                toggleBanner();
            }
        } catch (error) {
            setBannerData({
                smallText: error.message,
                fullText: error.message,
                color: "red",
                type: "error"
            });
            setButtonsEnabled(true);
            toggleBanner();
        }
    }

    function setButtonsEnabled(isEnabled) {
        setIsButtonEnabled(isEnabled);
        setIsDeleteButtonEnabled(isEnabled);
        setIsDefaultButtonEnabled(isEnabled);
    }

    function onDeleteButtonClick(){
        setTimeout(() => {
            setButtonsEnabled(false);
            DeleteReportRequest();
        }, 400);
    }

    function onDefaultButtonClick(){
        setTimeout(() => {
            setButtonsEnabled(false);
            DefaultReportRequest();
        }, 400);
    }

    return (
        <Formik
            initialValues={{
                tenantId: user?.tenantId ?? '',
                organizationId: user?.organizationId ?? '',
                name: report?.name ?? '',
                url: report?.url ?? '',
                categoryId: report?.categoryId ?? '',
                description: report?.description ?? '',
                isHidden: report?.isHidden ?? false 
            }}
            validationSchema={Yup.object({
                tenantId: SimpleRequiredInput,
                organizationId: SimpleRequiredInput,
                name: SimpleRequiredInput,
                url: UrlRequiredInput,
                categoryId: Yup.number()
                    .integer('El número ingresado debe ser entero')
                    .moreThan(-1, 'El ID de la categoría debe ser 0 o positivo')
                    .required('Requerido'),
                description: SimpleTextArea,
                isHidden: SimpleCheckbox
            })}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    values["id"] = report?.id;
                    setButtonsEnabled(false);
                    UpdateReportRequest(JSON.stringify(values, null, 0));
                    setSubmitting(false);
                }, 400);
            }}
        >
            <div className="md:grid md:grid-cols-1">
                <div className="mt-5 md:mt-0 md:col-span-2">
                    {!hiddenBanner ? <Banner {...bannerData} toggleBanner={toggleBanner}/> : null }
                    <Form>
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-2xl font-medium leading-6 text-gray-900">Modificar reporte</h3>
                                    <p className="mt-1 text-sm leading-5 text-gray-600 mb-5">
                                    Este reporte podrá verlo cualquier persona con acceso otorgado desde Power BI y perteneciente a tu organización.
                                    </p>
                                </div>
                                <TextInput label="Tenant Id" id="tenantId" name="tenantId" type="text" disabled hidden />
                                <TextInput label="Organización" id="organizationId" name="organizationId" type="text" disabled />
                                <TextInput label="Nombre del reporte" id="name" name="name" type="text" />
                                <TextInput label="URL Reporte" id="url" name="url" type="text" />
                                <Select label="Categoría" id="categoryId" name="categoryId">
                                    <option value="">Selecciona una categoría</option>
                                    { categories ? categories.map((category) => <option value={category.id} key={category.id}>{category.name}</option>) : <option value="">Loading...</option> }
                                </Select>
                                <TextArea label="Descripción" id="description" name="description" placeholder="Se verán los primeros 30 carácteres en el listado de reportes." />
                                <Checkbox label="Oculto" id="isHidden" name="isHidden" />
                                <div className="py-3 mt-10 bg-gray-50 flex justify-between">
                                    <span className="inline-flex rounded-md shadow-sm">
                                        <button type="button" onClick={() => onDeleteButtonClick()} className={`${isDeleteButtonEnabled ? `bg-red-600 hover:bg-red-500 focus:border-red-700 active:bg-red-700` : `bg-gray-600 hover:bg-gray-500 focus:border-gray-700 active:bg-gray-700`} inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-green transition duration-150 ease-in-out`} disabled={!isDeleteButtonEnabled}>
                                            Eliminar
                                        </button>
                                    </span>
                                    <span className="inline-flex rounded-md shadow-sm">
                                        <button type="button" onClick={() => onDefaultButtonClick()} className={`${isDefaultButtonEnabled ? `bg-blue-600 hover:bg-blue-500 focus:border-blue-700 active:bg-blue-700` : `bg-gray-600 hover:bg-gray-500 focus:border-gray-700 active:bg-gray-700`} inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-green transition duration-150 ease-in-out`} disabled={!isDeleteButtonEnabled}>
                                            Establecer reporte por defecto
                                        </button>
                                    </span>
                                    <span className="inline-flex rounded-md shadow-sm">
                                        <button type="submit" className={`${isButtonEnabled ? `bg-green-600 hover:bg-green-500 focus:border-green-700 active:bg-green-700` : `bg-gray-600 hover:bg-gray-500 focus:border-gray-700 active:bg-gray-700`} inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-green transition duration-150 ease-in-out`} disabled={!isButtonEnabled}>
                                            Confirmar
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    );
}

export default withRouter(EditReportForm);