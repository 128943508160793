import React, { useEffect } from 'react';
import * as signalR from '@microsoft/signalr';

import BusGrid from './busGrid';

const reducer = (state, { type, bus }) => {
    switch (type) {
        case 'newMessage': {
            const busIndex = state.busData.findIndex(data => data.movil === bus.movil);
            if (busIndex === -1) {
                return {busData: state.busData.concat([{...bus}])};
            }
            state.busData[busIndex] = {...bus}
            return {busData: state.busData};
        }
        default:
            console.log("No action defined");
            break;
    }
}

const initialState = {busData: []};

const RealTimeBusContent = ({hubTopic}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    async function startHubProcessing() {
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        try {
            await fetch('/api/BusExcess/StartBusExcess', requestOptions);
        } catch (error) {
            console.log('No se pudo iniciar el procesamiento de eventos.');
        }
    }

    async function stopHubProcessing() {
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        try {
            await fetch('/api/BusExcess/StopBusExcess', requestOptions);
        } catch (error) {
            console.log('No se pudo detener el procesamiento de eventos.');
        }
    }

    useEffect(() => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/busHub")
            .configureLogging(signalR.LogLevel.Error)  
            .build();

        if (hubTopic !== null && hubTopic !== undefined && hubTopic.length > 0) {
            startHubProcessing(); // TODO: Retry if no data after 5 seconds
            // Starts the SignalR connection
            hubConnection.start();
            console.log("Connection started")

        
            hubConnection.on(hubTopic ?? "none", message => {
                dispatch({type: 'newMessage', bus: JSON.parse(message)});
            });
        }

        return () => {
            console.log("Connection stopped");
            hubConnection.stop();
            stopHubProcessing();
        };
    }, [hubTopic]);

    function sortBuses() {
        if (state.busData.length > 1) {
            state.busData.sort( (a,b) => (a.movil > b.movil ? 1 : -1) );
        }
    }

 return (
    <div className="md:grid md:grid-cols-1">
        <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-2xl font-medium leading-6 text-gray-900">Listado de Buses</h3>
                        <p className="mt-1 text-sm leading-5 text-gray-600 mb-5">
                        Esta sección podrá verla cualquier persona perteneciente a tu organización.
                        </p>
                        <span className="inline-flex rounded-md shadow-sm mb-4">
                            <button type="button" onClick={() => sortBuses()} className="bg-blue-600 hover:bg-blue-500 focus:border-blue-700 active:bg-blue-700 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-green transition duration-150 ease-in-out" >
                                Ordenar listado
                            </button>
                        </span>
                        {hubTopic ? (state.busData && state.busData.length > 0 ? <BusGrid busData={state.busData} /> : <h1>Cargando datos...</h1>) : <h1>Invalid hub topic...</h1>}
                    </div>
                </div>
            </div>
        </div>
    </div>
 );
}

export default RealTimeBusContent;