import React from 'react';

const BusGridElement = ({movil, velocidad, velocidadLimite, exceso}) => {
    return (
        <div className={`rounded text-white font-bold my-2 mx-2 px-2 w-1/12 h-8 overflow-hidden border-2 ${exceso <= 0 ? 'bg-green-600 border-green-800' : 'bg-red-600 border-red-800'}`}>
            <p>{movil}</p>
        </div>
    );
}

const BusGrid = ({busData}) => {

    return (
    <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                { busData ? 
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-200">
                    <div className="flex flex-wrap -mx-2 overflow-hidden justify-center align-center text-center">
                        { busData.length > 0 ? busData.map((bus) => <BusGridElement {...bus} key={bus.movil} /> ) : null }
                    </div>
                </div> : <div className="text-lg leading-5 font-medium text-gray-900">Cargando buses...</div> }
            </div>
        </div>
    </div>
    );
}

export default BusGrid;