import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import TopNavBar from '../../partials/TopNavBar.js';
import SideNavBar from '../../partials/SideNavBar.js';

import RealTimeBusContent from './realTimeBusContent.js';

const RealTimeBus = ({user, reportMenu, menu, organizations, changeSelectedReport, changeSelectedCategory, changeSelectedOrganization, location }) => {

    const _menu = [...menu];
    _menu.forEach(element => {
        element.active = false
        if (element.message === "Real Time Data") {
            element.active = true;
        }
    });

    return (
        <div className="dashboard h-screen">
            <Helmet>
                <title>XemanticA - Real Time Data</title>
            </Helmet>
            <div className="top-nav-bar">
                <TopNavBar user={user} reportMenu={reportMenu} menu={menu} organizations={organizations} changeSelectedReport={changeSelectedReport} changeSelectedOrganization={changeSelectedOrganization}/>
            </div>
            <div className="mid-section flex flex-row" style={{height: "calc(100vh - 4rem)"}}>
                <div className="side-nav-bar hidden lg:block">
                    { reportMenu ? <SideNavBar reportMenu={reportMenu} changeSelectedReport={changeSelectedReport} changeSelectedCategory={changeSelectedCategory}/> : null}
                </div>
                <div className="realTime-container flex flex-1">
                    <div className="bg-gray-200 w-full overflow-auto">
                        <div className="max-w-6xl mx-auto lg:py-6 sm:px-6 lg:px-8">
                            { user ? <RealTimeBusContent hubTopic={location.state.hub}/> : <h1>Cargando...</h1>}
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default withRouter(RealTimeBus);