import React from 'react';
import Moment from 'moment-timezone';
import { Link, withRouter } from 'react-router-dom';

const CategoryTableRow = ({id, tenantId, organizationId, name, description, isHidden, created, modified}) => {
    Moment.locale('es');

    return (
        <tr>
            <td className="px-6 py-4 whitespace-no-wrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        <div className="text-sm leading-5 font-medium text-gray-900">
                            {name}
                        </div>
                        <div className="text-sm leading-5 text-gray-500">
                            {description}
                        </div>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${!isHidden ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {isHidden ? 'Oculto' : 'Visible'}
                </span>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                {Moment(created+'Z').tz('America/Santiago').format('DD/MM/YYYY HH:mm:ss')}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600">
                {Moment(modified+'Z').tz('America/Santiago').format('DD/MM/YYYY HH:mm:ss')}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                <Link to={{
                    pathname: "/category/editCategory",
                    state: { 
                        categoryId: id,
                        tenantId: tenantId,
                        organizationId: organizationId
                    }
                }}>
                    <button className="text-indigo-600 hover:text-indigo-900">Modificar</button>
                </Link>
            </td>
        </tr>
    );
}

const CategoryTable = ({reportMenu}) => {

    let categoryList = [];
    
    if (reportMenu) {
        reportMenu.forEach((category) => {
            categoryList.push({
                id: category.id,
                tenantId: category.tenantId,
                organizationId: category.organizationId,
                name: category.name,
                description: category.description.slice(0,30),
                isHidden: category.isHidden,
                isDeleted: category.isDeleted,
                created: category.created,
                modified: category.modified
            });
        });
    }

    return (
    <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                { reportMenu ? 
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                    Nombre de la categoría
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                    Visible
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                    Fecha de creación <span className="font-light text-xs text-gray-500">(DD/MM/YYYY HH:mm:ss)</span>
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-700 uppercase tracking-wider">
                                    Fecha de modificación <span className="font-light text-xs text-gray-500">(DD/MM/YYYY HH:mm:ss)</span>
                                </th>
                                <th className="px-6 py-3 bg-gray-50"></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            { categoryList.length > 0 ? categoryList.map((category) => !category.isDeleted ? <CategoryTableRow {...category} key={category.id} /> : null) : null }
                        </tbody>
                    </table>
                </div> : <div className="text-lg leading-5 font-medium text-gray-900">Cargando Categorías...</div> }
            </div>
        </div>
    </div>
    );
}

export default withRouter(CategoryTable);