import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Transition } from '@tailwindui/react';
import XemanticALogo from '../components/common/xemanticaLogo.js';

import './../static/customTopNavBar.css';
import MobileMenuButton from './TopNavBar/mobileMenuButton.js';
import MobileReportMenuCategory from './TopNavBar/mobileReportMenuCategory.js';
import MobileProfile from './TopNavBar/mobileProfile.js';
import SelectedMenuOption from './TopNavBar/selectedMenuOption.js';
import MenuOption from './TopNavBar/menuOption.js';
import MobileSelectedMenuOption from './TopNavBar/mobileSelectedMenuOption.js';
import MobileMenuOption from './TopNavBar/mobileMenuOption.js';
import ProfileDropdown from './TopNavBar/profileDropdown.js';
import OrganizationDropdown from './TopNavBar/OrganizationDropdown.js';

const TopNavBar = (props) => {
    const [menu, setMenu] = useState([...props.menu]);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    function changeSelectedMenu(item) {
        let option;
        const _menu = [...menu];
        _menu.forEach(element => {
            element.active = false
            if (element.message === item.message) {
                element.active = true;
                option = element;
            }
        });
        setMenu(_menu);
        if (option) {
            props.history.push(option.href);
        }
    }

    function changeReport(selectedItem) {
        props.changeSelectedReport(selectedItem);
        if (window.location.pathname !== "/dashboard") {
            props.history.push("/dashboard");
        }
    }

    function deselectMenu() {
        const _menu = [...menu];
        _menu.forEach(element => {
            element.active = false
        });
        setMenu(_menu);
    }

    return (
        <div>
            <nav className="bg-gray-XT">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex-row">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <XemanticALogo href={'http://www.xemantics.com'} />
                            <div className="hidden lg:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    {menu.map((item) => item.active ? <SelectedMenuOption value={item.message} href={item.href} key={item.message} /> : <MenuOption value={item.message} href={item.href} key={item.message} onClick={() => changeSelectedMenu(item)} />)}
                                </div>
                            </div>
                        </div>

                        <div className="hidden lg:block">
                            <div className="ml-4 flex items-center lg:ml-6">
                                
                                { props.organizations && props.user && props.user.role === "SuperAdmin" ? <>
                                    <label id="listbox-label" className="block text-sm font-medium text-gray-400 mr-3">
                                        Organización: 
                                    </label>
                                    <OrganizationDropdown user={props.user} organizations={props.organizations} changeSelectedOrganization={props.changeSelectedOrganization} />
                                </> : null }

                                <button className="p-1 ml-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700" aria-label="Notifications">
                                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                    </svg>
                                </button>

                                <ProfileDropdown user={props.user} />

                            </div>
                        </div>

                        <MobileMenuButton isOpen={isMobileMenuOpen} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
                    </div>

                    {/* TODO: Add organization selection to mobile menu */}
                    <Transition show={isMobileMenuOpen} enter="transition ease-out duration-200" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-1000" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                        <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} lg:hidden`}>
                            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                {menu.map((item) => item.active ? <MobileSelectedMenuOption value={item.message} key={'mobile' + item.message} /> : <MobileMenuOption value={item.message} key={'mobile' + item.message} onClick={() => changeSelectedMenu(item)} />)}
                            </div>

                            <MobileProfile user={props.user} onClick={() => deselectMenu()} />


                            <div className="pb-3 border-t border-gray-600">
                                <div className="mt-3 px-2 space-y-1">
                                    <ul>
                                        {props.reportMenu ? 
                                        props.reportMenu.map((category) => ( !category.isHidden ? 
                                            <MobileReportMenuCategory key={category.id + category.name} value={category.name} changeReport={changeReport} reports={category.reports} active={category.isActive} /> :
                                            null)) :
                                        null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Transition>

                </div>
            </nav>
        </div>
    );
}

export default withRouter(TopNavBar);