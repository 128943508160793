import React from 'react';
import {Helmet} from "react-helmet";
import TopNavBar from '../../partials/TopNavBar.js';
import SideNavBar from '../../partials/SideNavBar.js';

import BusTable from './hubTable.js';

const RealTimeMenu = ({user, reportMenu, menu, organizations, changeSelectedReport, changeSelectedCategory, changeSelectedOrganization }) => {

    const _menu = [...menu];
    _menu.forEach(element => {
        element.active = false
        if (element.message === "Real Time Data") {
            element.active = true;
        }
    });

    return (
        <div className="dashboard h-screen">
            <Helmet>
                <title>XemanticA - Real Time Data</title>
            </Helmet>
            <div className="top-nav-bar">
                <TopNavBar user={user} reportMenu={reportMenu} menu={menu} organizations={organizations} changeSelectedReport={changeSelectedReport} changeSelectedOrganization={changeSelectedOrganization} />
            </div>
            <div className="mid-section flex flex-row" style={{height: "calc(100vh - 4rem)"}}>
                <div className="side-nav-bar hidden lg:block">
                    { reportMenu ? <SideNavBar reportMenu={reportMenu} changeSelectedReport={changeSelectedReport} changeSelectedCategory={changeSelectedCategory}/> : null}
                </div>
                <div className="realTime-container flex flex-1 overflow-auto">
                    <div className="bg-gray-200 w-full">
                        <div className="max-w-6xl mx-auto lg:py-6 sm:px-6 lg:px-8">
                            <div className="md:grid md:grid-cols-1">
                                <div className="mt-5 md:mt-0 md:col-span-2">
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="px-4 py-5 bg-white sm:p-6">
                                            <div className="px-4 sm:px-0">
                                                <h3 className="text-2xl font-medium leading-6 text-gray-900">Listado de datos en tiempo real</h3>
                                                <p className="mt-1 text-sm leading-5 text-gray-600 mb-5">
                                                Esta sección podrá verla cualquier persona perteneciente a tu organización.
                                                </p>
                                                { user ? <BusTable /> : <h1>Cargando...</h1>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default RealTimeMenu;