import React from 'react';
import { withRouter } from 'react-router-dom';
import { Transition } from '@tailwindui/react';

import './../static/customSideNavBar.css'

function Category(props) {
    return (
        <li className="my-px">
            <button className="square w-full" onClick={props.onClick}>
                <span className="flex flex-row items-center h-12 px-4 rounded-lg font-medium text-lg text-gray-400 hover:bg-gray-X-button-hover ml-2 mr-2">{props.value}</span>
            </button>
            
            <Transition show={(props.active)} enter="transition ease-out duration-200" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-1000" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <ul className={`${(props.active) ? 'block' : 'hidden'}`}>
                    {props.reports.map((report) => !report.isHidden ? 
                        (report.isActive ? 
                            <CategorySelectedOption key={report.id + report.name} value={report.name} onClick={() => props.changeReport(report)}/> :
                            <CategoryOption key={report.id + report.name} value={report.name} onClick={() => props.changeReport(report)}/>) :
                        null)}
                </ul>
            </Transition>
        </li>
    );
}

function CategoryOption(props) {
    return (
        <li className="my-px ml-2 mr-2">
            <button className="flex flex-row items-center h-10 px-4 rounded-lg text-gray-500 hover:bg-gray-X-button-hover w-full" onClick={props.onClick}>
                <span className="ml-3">{props.value}</span>
            </button>
        </li>
    );
}

function CategorySelectedOption(props) {
    return (
        <li className="my-px ml-2 mr-2">
            <button className="flex flex-row items-center h-10 px-4 rounded-lg text-white bg-gray-X-button-selected hover:bg-gray-X-button-hover w-full mr-2" onClick={props.onClick}>
                <span className="ml-3">{props.value}</span>
            </button>
        </li>
    );
}

const SideNavBar = (props) => {

    function changeReport(selectedItem) {
        props.changeSelectedReport(selectedItem);
        if (window.location.pathname !== "/dashboard") {
            props.history.push("/dashboard");
        }
    }

    return (
        <nav className="flex flex-row bg-gray-X w-full h-full justify-between overflow-auto" style={{minWidth: "12rem"}}>
            <div className="mt-5 mb-10 w-full">
                    <ul>
                        { props.reportMenu ?
                            props.reportMenu.map((category) => (!category.isHidden ? <Category key={category.id + category.name} value={category.name} onClick={() => props.changeSelectedCategory(category)} changeReport={changeReport} reports={category.reports} active={category.isActive}/>: null)) :
                            <span className="flex flex-row items-center h-12 px-4 rounded-lg font-medium text-lg text-gray-400 hover:bg-gray-700 ml-2 mr-2">Cargando...</span>
                        }
                    </ul>
            </div>
        </nav>
    );
}

export default withRouter(SideNavBar);