import React from 'react';
import {Helmet} from "react-helmet";
import TopNavBar from '../../partials/TopNavBar.js'
import SideNavBar from '../../partials/SideNavBar.js'
import NewCategoryForm from './newCategoryForm.js'

const NewCategory = ({user, reportMenu, menu, organizations, changeSelectedReport, changeSelectedCategory, changeSelectedOrganization }) => {

    const _menu = [...menu];
    _menu.forEach(element => {
        element.active = false
        if (element.message === "Categorías") {
            element.active = true;
        }
    });

    return (
        <div className="dashboard h-screen">
            <Helmet>
                <title>XemanticA - Nueva Categoría</title>
            </Helmet>
            <div className="top-nav-bar">
                <TopNavBar user={user} reportMenu={reportMenu} menu={menu} organizations={organizations} changeSelectedReport={changeSelectedReport} changeSelectedOrganization={changeSelectedOrganization}/>
            </div>
            <div className="mid-section flex flex-row" style={{height: "calc(100vh - 4rem)"}}>
                <div className="side-nav-bar hidden lg:block">
                    { reportMenu ? <SideNavBar reportMenu={reportMenu} changeSelectedReport={changeSelectedReport} changeSelectedCategory={changeSelectedCategory}/> : null}
                </div>
                <div className="newCategory-container flex flex-1">
                    <div className="bg-gray-200 w-full overflow-auto">
                        <div className="max-w-6xl mx-auto lg:py-6 sm:px-6 lg:px-8">
                            { user ? <NewCategoryForm user={user}/> : <h1>Cargando...</h1>}
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default NewCategory;