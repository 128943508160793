import React from 'react';

const MobileProfile = (props) => {
    return (
        <div className="pt-4 pb-3 border-t border-gray-600">
            <div className="flex items-center px-5 space-x-3">
                <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""></img>
                </div>
                <div className="space-y-1">
                    <div className="text-base font-medium leading-none text-white">{props.user?.givenName ?? 'Jhon'} {props.user?.surname ?? 'Doe'}</div>
                    <div className="text-sm font-medium leading-none text-gray-400">{props.user?.upn ?? 'jhon@doe.com'}</div>
                </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
                <button onClick={props.onClick} className="block px-3 py-2 w-full text-justify rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Your Profile</button>

                <button onClick={props.onClick} className="block px-3 py-2 w-full text-justify rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Settings</button>

                <button onClick={() => window.location.replace(window.location.origin + '/AzureAD/Account/SignOut')} className="block px-3 py-2 w-full text-justify rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Logout</button>
            </div>
        </div>
    );
}

export default MobileProfile;