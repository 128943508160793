import React, { useState } from 'react';
import { Transition } from '@tailwindui/react';
import ProfileRelevantOptions from './profileRelevantOptions.js';
import ProfileNormalOptions from './profileNormalOptions.js';

const ProfileDropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="ml-3 relative">
            <div>
                <button className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid" id="user-menu" aria-label="User menu" aria-haspopup="true" onClick={() => setIsOpen(!isOpen)}>
                    <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""></img>
                </button>
            </div>
            <Transition
                show={isOpen}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                {(ref) => (
                    <div ref={ref} className="origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg overflow-auto max-h-screen" style={{ minWidth: "15rem" }}>
                        <div className="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                            <div className="text-center p-6  border-b">
                                <img className="h-24 w-24 rounded-full mx-auto" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Randy Robertson" />
                                <p className="pt-2 text-lg font-semibold">{(props.user?.givenName !== "Not found" ? props.user?.givenName : "Jhon") ?? "Jhon"} {(props.user?.surname !== "Not found" ? props.user?.surname : "Doe") ?? "Doe"}</p>
                                <p className="text-sm text-gray-600">{props.user?.upn ?? 'jhon@doe.com'}</p>
                            </div>
                            <ProfileRelevantOptions />
                            <ProfileNormalOptions />
                        </div>
                    </div>
                )}
            </Transition>
        </div>
    );
}

export default ProfileDropdown;