import React from 'react';

const InfoIcon = ({color}) => {

    if (!color) {
        color = "white"
    }

    /* Heroicon name: information-circle */
    return (
        <svg className={`w-6 h-6 text-${color}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
    );
}

const WarningIcon = ({color}) => {

    if (!color) {
        color = "white"
    }

    /* Heroicon name: exclamation */
    return (
        <svg className={`w-6 h-6 text-${color}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
        </svg>
    );
}

const ErrorIcon = ({color}) => {

    if (!color) {
        color = "white"
    }

    /* Heroicon name: ban */
    return (
        <svg className={`w-6 h-6 text-${color}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>
        </svg>
    );
}

const SuccessIcon = ({color}) => {

    if (!color) {
        color = "white";
    }

    /* Heroicon name: check-circle */
    return (
        <svg className={`w-6 h-6 text-${color}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
    );
}

export {
    InfoIcon,
    WarningIcon,
    ErrorIcon,
    SuccessIcon
}