import React from 'react';
import Logo from '../../static/logo.png';

function XemanticALogo(props) {
    return (
        <div className="flex-shrink-0 flex justify-center align-center">
            <a href={props.href}>
                <img className="" src={Logo} alt="Xemantics Logo"></img>
            </a>
        </div>
    );
}

export default XemanticALogo;