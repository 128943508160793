import * as Yup from 'yup'

const SimpleRequiredInput = Yup.string()
    .trim()
    .max(50, 'Debe contener 50 caracteres o menos')
    .required('Requerido');

const SimpleInput = Yup.string()
    .trim()
    .max(50, 'Debe contener 50 caracteres o menos')
    .ensure();

const EmailRequiredInput = Yup.string()
    .trim()
    .max(50, 'Debe contener 50 caracteres o menos')
    .email('Dirección de correo electrónico inválida')
    .required('Requerido');

const EmailInput = Yup.string()
    .trim()
    .max(50, 'Debe contener 50 caracteres o menos')
    .email('Dirección de correo electrónico inválida')
    .ensure();

const UrlRequiredInput = Yup.string()
    .trim()
    .max(2000, 'Debe contener 2000 caracteres o menos')
    .url('Dirección de URL inválida')
    .required('Requerido');

const UrlInput = Yup.string()
    .trim()
    .max(2000, 'Debe contener 2000 caracteres o menos')
    .url('Dirección de URL inválida')
    .ensure();

const PositiveNumberRequiredInput = Yup.number()
    .integer('El número ingresado debe ser entero')
    .moreThan(-1, 'El número ingresado debe ser 0 o positivo')
    .required('Requerido');

const PositiveNumberInput = Yup.number()
    .integer('El número ingresado debe ser entero')
    .moreThan(-1, 'El número ingresado debe ser 0 o positivo')
    .default(() => ({ number: 0 }));

const SimpleRequiredTextArea = Yup.string()
    .trim()
    .max(4000, 'Debe contener 4000 caracteres o menos')
    .required('Requerido');

const SimpleTextArea = Yup.string()
    .trim()
    .max(4000, 'Debe contener 4000 caracteres o menos')
    .ensure();

const SimpleCheckbox = Yup.boolean();

export {
    SimpleRequiredInput,
    SimpleInput,
    EmailRequiredInput,
    EmailInput,
    UrlRequiredInput,
    UrlInput,
    PositiveNumberRequiredInput,
    PositiveNumberInput,
    SimpleRequiredTextArea,
    SimpleTextArea,
    SimpleCheckbox
}