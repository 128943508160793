import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { Redirect, Route, Switch } from 'react-router';
import './static/tailwind.output.css';

import Login from './components/login.js';
import Dashboard from './components/dashboard.js';
import Profile from './components/profile/profile.js';
import NewReport from './components/reportConfig/newReport.js'
import EditReport from './components/reportConfig/editReport.js'
import ReportIndex from './components/reportConfig/reportIndex.js'
import NewCategory from './components/categoryConfig/newCategory.js'
import EditCategory from './components/categoryConfig/editCategory.js'
import CategoryIndex from './components/categoryConfig/categoryIndex.js'
import RealTimeMenu from './components/realTimeData/realTimeMenu.js'
import RealTimeBus from './components/realTimeData/realTimeBus.js'

const baseUrl = window.location.origin;
const baseRelativeUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const Index = (props) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [reportMenu, setReportMenu] = useState([]);
    const [menu] = useState([
        { message: 'Dashboard', href: '/dashboard', active: true },
        { message: 'Categorías', href: '/category/all', active: false },
        { message: 'Reportes', href: '/report/all', active: false },
        { message: 'Real Time Data', href: '/realTime/menu', active: false}
    ]);
    const [organizations, setOrganizations] = useState([]);

    async function getUser() {
        const userRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        try {
            const result = await fetch(baseUrl + '/api/user/UserProfile', userRequestOptions)
                .then(response => response.json())
            setUser(result);
        } catch (error) {
            console.log('Error, usuario no logeado.');
            setError(error);
            if (window.location.pathname !== '/' && window.location.pathname !== '/ClientApp/build/' && window.location.pathname !== '/Account/SignOut') {
                alert('Error, usuario no logeado.');
                window.location.pathname = '/';
            }
        }
    }

    async function getReports() {
        const reportRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tenantId: user.tenantId,
                organizationId: user.organizationId
            })
        };
        try {
            const result = await fetch(baseUrl + '/api/report/GetUserReports', reportRequestOptions)
                .then(response => response.json())
            if (result.statusCode === 200 && result.status === "OK") {
                setReportMenu([...result.categories]);
            }
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    async function getOrganizations() {
        const organizationRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const result = await fetch(baseUrl + '/api/user/GetOrganizations', organizationRequestOptions)
                .then(response => response.json())
            if (result.statusCode === 200 && result.status === "OK") {
                setOrganizations([...result.organizations]);
            }
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        getOrganizations();
    }, []);

    useEffect(() => {
        if (user) {
            getReports();
        }
    }, [user])

    function changeSelectedReport(selectedItem) {
        const _reportMenu = [...reportMenu];
        _reportMenu.forEach(category => {
            category.isActive = false;
            category.reports.forEach(report => {
                report.isActive = false;
                if (report.name === selectedItem.name && report.id === selectedItem.id) {
                    report.isActive = true;
                    category.isActive = true;
                }
            });
        });
        setReportMenu(_reportMenu);
    }

    function changeSelectedCategory(selectedCategory) {
        const _reportMenu = [...reportMenu];
        _reportMenu.forEach(category => {
            category.isActive = false;
            if ((category.name === selectedCategory.name && category.id === selectedCategory.id)) {
                category.isActive = true;
            }
        });
        setReportMenu(_reportMenu);
    }

    function changeSelectedOrganization(selectedOrganization) {
        const _user = {...user};
        if (_user.role === "SuperAdmin") {
            _user.organizationId = selectedOrganization.organizationId;
            setUser(_user)
        }
    }

    return (
        <BrowserRouter basename={baseRelativeUrl}>
            <Switch>
                <Route
                    path="/"
                    exact
                    component={Login}>
                    {user && !error ? <Redirect to="/dashboard" /> : null}
                </Route>
                <Route
                    path="/Account/SignOut"
                    exact >
                    <Redirect to="/" />
                    </Route>
                <Route
                    path="/dashboard"
                    exact
                    render={(props) => <Dashboard {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                        />}
                />
                <Route
                    path="/profile"
                    exact
                    render={(props) => <Profile {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
                <Route
                    path="/report/all"
                    exact
                    render={(props) => <ReportIndex {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
                <Route
                    path="/report/newReport"
                    exact
                    render={(props) => <NewReport {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
                <Route
                    path="/report/editReport"
                    exact
                    render={(props) => <EditReport {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
                <Route
                    path="/category/all"
                    exact
                    render={(props) => <CategoryIndex {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
                <Route
                    path="/category/newCategory"
                    exact
                    render={(props) => <NewCategory {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
                <Route
                    path="/category/editCategory"
                    exact
                    render={(props) => <EditCategory {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
                <Route
                    path="/realTime/menu"
                    exact
                    render={(props) => <RealTimeMenu {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
                <Route
                    path="/realTime/busData"
                    exact
                    render={(props) => <RealTimeBus {...props}
                        user={user}
                        reportMenu={reportMenu}
                        menu={menu}
                        organizations={organizations}
                        changeSelectedReport={changeSelectedReport}
                        changeSelectedCategory={changeSelectedCategory}
                        changeSelectedOrganization={changeSelectedOrganization}
                    />}
                />
            </Switch>
        </BrowserRouter>
    );
}

ReactDOM.render(
    <Index />,
    rootElement);

registerServiceWorker();

